var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-provider',{attrs:{"rules":_vm.validations.zones,"name":"zones","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"value":_vm.bettingPool.zoneId,"items":_vm.zones,"error-messages":errors,"item-text":"label","item-value":"value","label":_vm.$t('attributes.bettingPools.zone')},on:{"input":_vm.zoneChanged}})]}}])}),_c('validation-provider',{attrs:{"rules":_vm.validations.deactivationBalance,"name":"deactivation_balance","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.bettingPool.deactivationBalance,"label":_vm.$t('attributes.bettingPools.deactivation_balance'),"error-messages":errors,"name":"deactivation_balance","type":"number"},on:{"input":_vm.deactivationBalanceChanged}})]}}])}),_c('validation-provider',{attrs:{"rules":_vm.validations.dailySaleLimit,"name":"daily_sale_limit","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.bettingPool.dailySaleLimit,"label":_vm.$t('attributes.bettingPools.daily_sale_limit'),"error-messages":errors,"name":"daily_sale_limit","type":"number"},on:{"input":_vm.dailySaleLimitChanged}})]}}])}),_c('validation-provider',{attrs:{"rules":_vm.validations.maximumAmountPerPlay,"name":"maximum_amount_per_play","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.bettingPool.maximumAmountPerPlay,"label":_vm.$t('attributes.bettingPools.maximum_amount_per_play'),"error-messages":errors,"name":"maximum_amount_per_play","type":"number"},on:{"input":function (value) { return _vm.$emit('update:betting-pool', Object.assign({}, _vm.bettingPool, {maximumAmountPerPlay: value})); }}})]}}])}),_c('validation-provider',{attrs:{"rules":_vm.validations.cancelTicketTime,"name":"cancel_ticket_time","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.bettingPool.cancelTicketTime,"label":_vm.$t('attributes.bettingPools.cancel_ticket_time'),"error-messages":errors,"name":"cancel_ticket_time","type":"number"},on:{"input":_vm.cancelTicketTimeChanged}})]}}])}),_c('btn-group-text',{attrs:{"title":_vm.$t('attributes.bettingPools.winning_ticket_control'),"selected":_vm.bettingPool.winningTicketControl,"options":_vm.winningTicketControlModes,"name":"winning_ticket_control","color":"info","attribute":"name","mandatory":"","translation-base":"enumerations.winningTicketControlSettingCategory"},on:{"change":_vm.winningTicketControlChanged}}),_c('validation-provider',{attrs:{"rules":_vm.validations.active,"name":"active","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-switch',{staticClass:"pl-4",attrs:{"input-value":_vm.bettingPool.active,"label":_vm.$t('attributes.bettingPools.active'),"error-messages":errors,"name":"active","inset":""},on:{"click":_vm.activeChanged}})]}}])}),_c('validation-provider',{attrs:{"rules":_vm.validations.allowChangePassword,"name":"allow_change_password","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-switch',{staticClass:"pl-4",attrs:{"value":_vm.bettingPool.allowChangePassword,"label":_vm.$t('attributes.bettingPools.allow_change_password'),"error-messages":errors,"name":"allow_change_password","inset":""},on:{"click":_vm.allowChangePasswordChanged}})]}}])}),_c('validation-provider',{attrs:{"rules":_vm.validations.allowExtendedRound,"name":"allow_extended_round","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-switch',{staticClass:"pl-4",attrs:{"value":_vm.bettingPool.allowExtendedRound,"label":_vm.$t('attributes.bettingPools.allow_extended_round'),"error-messages":errors,"name":"allow_extended_round","inset":""},on:{"click":_vm.allowExtendedRoundChanged}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }