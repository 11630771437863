<template>
  <div>
    <validation-provider
      v-slot="{ errors }"
      :rules="validations.name"
      name="name"
      slim>
      <v-text-field
        :value="bettingPool.name"
        :label="$t('attributes.bettingPools.name')"
        :error-messages="errors"
        name="name"
        type="text"
        @input="nameChanged" />
    </validation-provider>
    <v-text-field
      :value="poolSequence"
      :label="$t('attributes.bettingPools.number')"
      name="sequence"
      type="text"
      disabled />
    <validation-provider
      v-slot="{ errors }"
      :rules="validations.reference"
      name="reference"
      slim>
      <v-text-field
        :value="bettingPool.reference"
        :label="$t('attributes.bettingPools.reference')"
        :error-messages="errors"
        name="name"
        type="text"
        @input="referenceChanged" />
    </validation-provider>
    <validation-provider
      v-slot="{ errors }"
      :rules="validations.location"
      name="location"
      slim>
      <v-text-field
        :value="bettingPool.location"
        :label="$t('attributes.bettingPools.location')"
        :error-messages="errors"
        name="name"
        type="text"
        @input="locationChanged" />
    </validation-provider>
  </div>
</template>

<script>
export default {
  name: "PoolGeneralForm",
  props: {
    bettingPool: {
      type: Object,
      required: true,
    },
    validations: {
      type: Object,
      required: true,
    },
    poolSequence: {
      type: String,
      required: true,
    },
  },
  methods: {
    nameChanged(value) {
      const updatedBettingPool = {
        ...this.bettingPool,
        name: value,
      };

      this.$emit("update:betting-pool", updatedBettingPool);
    },
    referenceChanged(value) {
      const updatedBettingPool = {
        ...this.bettingPool,
        reference: value,
      };

      this.$emit("update:betting-pool", updatedBettingPool);
    },
    locationChanged(value) {
      const updatedBettingPool = {
        ...this.bettingPool,
        location: value,
      };

      this.$emit("update:betting-pool", updatedBettingPool);
    },
  },
};
</script>

<style
  lang="scss"
  scoped>

</style>
