<template>
  <v-row
    align="center"
    justify="center">
    <v-col md="10">
      <validation-observer
        ref="validationObserver">
        <v-card>
          <v-card-title>
            {{
              $t("crudActions." + (id !== null
                ? "updateModel"
                : "createModel"), { model: $t("models.bettingPools.single") })
            }}
          </v-card-title>
          <v-card-text>
            <v-tabs
              v-model="tab"
              fixed-tabs>
              <v-tab
                key="general"
                :class="errorClassForTab('general')">
                {{ $t("views.bettingPools.create_or_update.tab_names.general") }}
              </v-tab>
              <v-tab
                key="configuration"
                :class="errorClassForTab('configuration')">
                {{ $t("views.bettingPools.create_or_update.tab_names.configuration") }}
              </v-tab>
              <v-tab
                key="footers"
                :class="errorClassForTab('footers')">
                {{ $t("views.bettingPools.create_or_update.tab_names.footers") }}
              </v-tab>
              <v-tab
                key="cash-outs-and-commissions"
                :class="errorClassForTab('cashOutsAndCommissions')">
                {{ $t("views.bettingPools.create_or_update.tab_names.cash_outs_and_commissions") }}
              </v-tab>
              <v-tab
                key="schedules"
                :class="errorClassForTab('schedules')">
                {{ $t("views.bettingPools.create_or_update.tab_names.schedules") }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <validation-observer
                ref="generalValidationObserver"
                slim>
                <v-tab-item
                  key="general"
                  eager>
                  <!-- pool form here -->
                  <pool-general-form
                    :betting-pool.sync="form.bettingPool"
                    :validations="validationRules"
                    :pool-sequence="params.poolSequence" />
                  <user-form
                    v-if="currentMode === CREATE_MODE"
                    :user.sync="form.bettingPool.userAttributes"
                    :validations="validationRules.user" />
                </v-tab-item>
              </validation-observer>
              <validation-observer
                ref="configurationValidationObserver"
                slim>
                <v-tab-item
                  key="configuration"
                  eager>
                  <pool-configuration-form
                    :betting-pool.sync="form.bettingPool"
                    :validations="validationRules"
                    :zones="params.zones" />
                </v-tab-item>
              </validation-observer>
              <validation-observer
                ref="footersValidationObserver"
                slim>
                <v-tab-item
                  key="footers"
                  eager>
                  <group-or-pool-footer-form
                    :group-or-pool.sync="form.bettingPool"
                    :validations="validationRules" />
                </v-tab-item>
              </validation-observer>
              <validation-observer
                ref="cashOutsAndCommissionsValidationObserver"
                slim>
                <v-tab-item
                  key="cash-outs-and-commissions"
                  eager>
                  <group-or-pool-cash-out-and-commission-form
                    :group-or-pool.sync="form.bettingPool"
                    :validations="validationRules" />
                </v-tab-item>
              </validation-observer>
              <validation-observer
                ref="schedulesValidationObserver"
                slim>
                <v-tab-item key="schedules">
                  <group-or-pool-schedule-form
                    :group-or-pool.sync="form.bettingPool"
                    :validations="validationRules" />
                </v-tab-item>
              </validation-observer>
            </v-tabs-items>
            <v-card-actions>
              <v-btn
                color="primary"
                @click="$router.push({ name: $routes.bettingPool.indexRoute.name })">
                {{ $t("actions.back_to_list") }}
              </v-btn>
              <v-spacer />
              <v-btn
                color="success"
                @click="createOrUpdateBettingPool">
                {{
                  $t("crudActions." + (id !== null
                    ? "update"
                    : "create"))
                }}
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </validation-observer>
    </v-col>
  </v-row>
</template>

<script>
import { fetchBettingPool, createBettingPool, updateBettingPool, getCreateOrUpdateParams } from "@/api/bettingPool.js";
import { ValidationObserver } from "vee-validate";
import Validatable from "../../mixins/Validatable.js";
import UserForm from "@/components/user/UserForm.vue";
import GroupOrPoolFooterForm from "@/components/groupOrPool/GroupOrPoolFooterForm.vue";
import GroupOrPoolCashOutAndCommissionForm from "@/components/groupOrPool/GroupOrPoolCashOutAndCommissionForm.vue";
import GroupOrPoolScheduleForm from "@/components/groupOrPool/GroupOrPoolScheduleForm.vue";
import Weekday from "@/imported/Weekday.json";
import PoolGeneralForm from "@/pages/bettingPool/PoolGeneralForm.vue";
import PoolConfigurationForm from "@/pages/bettingPool/PoolConfigurationForm.vue";
import { resetData } from "@/utils/ComponentHelper.js";
import WinningTicketControl from "@/imported/WinningTicketControlSettingCategory.json";

const CREATE_MODE = 1, UPDATE_MODE = 2;

function data() {
  return {
    form: {
      bettingPool: {
        name: null,
        reference: null,
        location: null,
        userAttributes: {
          username: "",
          password: "",
          passwordConfirmation: "",
        },
        active: "true",
        zoneId: null,
        deactivationBalance: null,
        dailySaleLimit: null,
        maximumAmountPerPlay: null,
        cancelTicketTime: null,
        winningTicketControl: WinningTicketControl.groupPreference,
        allowChangePassword: false,
        allowExtendedRound: false,
        firstFooter: null,
        secondFooter: null,
        thirdFooter: null,
        fourthFooter: null,
        cashOuts: [
          { weekday: Weekday.sunday, amount: null },
          { weekday: Weekday.monday, amount: null },
          { weekday: Weekday.tuesday, amount: null },
          { weekday: Weekday.wednesday, amount: null },
          { weekday: Weekday.thursday, amount: null },
          { weekday: Weekday.friday, amount: null },
          { weekday: Weekday.saturday, amount: null },
        ],
        commission: 0,
        schedules: [
          { weekday: Weekday.sunday, openingTime: "08:00", closingTime: "18:00" },
          { weekday: Weekday.monday, openingTime: "08:00", closingTime: "18:00" },
          { weekday: Weekday.tuesday, openingTime: "08:00", closingTime: "18:00" },
          { weekday: Weekday.wednesday, openingTime: "08:00", closingTime: "18:00" },
          { weekday: Weekday.thursday, openingTime: "08:00", closingTime: "18:00" },
          { weekday: Weekday.friday, openingTime: "08:00", closingTime: "18:00" },
          { weekday: Weekday.saturday, openingTime: "08:00", closingTime: "18:00" },
        ],
      },
    },
    tab: null,
    timeZones: [],
    params: {
      poolSequence: "----",
      zones: [],
    },
    CREATE_MODE,
    UPDATE_MODE,
  };
}

export default {
  name: "BettingPoolCreateOrUpdate",
  components: {
    ValidationObserver,
    UserForm,
    GroupOrPoolFooterForm,
    GroupOrPoolCashOutAndCommissionForm,
    GroupOrPoolScheduleForm,
    PoolGeneralForm,
    PoolConfigurationForm,
  },
  mixins: [Validatable],
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data,
  computed: {
    validationRules() {
      return {
        name: "required|min:3",
        user: {
          username: "required",
          password: "required",
          passwordConfirmation: "required_if:password|confirm:password",
        },
        reference: "required",
        location: "required",
        zones: "required",
        cancelTicketTime: "numeric",
        winningTicketControl: "required",
        cashOuts: "numeric",
        commission: "numeric|between:0,50",
        schedules: "required",
        maximumAmountPerPlay: 'numeric'
      };
    },
    currentMode() {
      if (typeof this.id === "number") {
        return UPDATE_MODE;
      }
      return CREATE_MODE;
    },
  },
  watch: {
    "$route"() {
      this.loadParams();
    },
  },
  created() {
    this.loadParams();
  },
  methods: {
    async createOrUpdateBettingPool() {
      const isValid = await this.validateForm();

      if (isValid) {
        const submitForm = {};
        delete Object.assign(submitForm, this.form.bettingPool, { ["bettingPoolCashOutsAttributes"]: this.form.bettingPool.cashOuts }).cashOuts;
        delete Object.assign(submitForm, submitForm, { ["bettingPoolSchedulesAttributes"]: this.form.bettingPool.schedules }).schedules;

        (this.id === null
          ? createBettingPool(submitForm)
          : updateBettingPool(this.id, submitForm)).
          then(() => {
            this.$router.push({
              name: this.$routes.bettingPool.indexRoute.name,
            });
          }).
          catch(errorInfo => {
            const errors = errorInfo.response.data.failures,
              validationObserver = this.$refs.validationObserver;

            validationObserver.setErrors(errors);
          });
      }
    },
    async validateForm() {
      const {
        generalValidationObserver,
        configurationValidationObserver,
        footersValidationObserver,
        cashOutsAndCommissionsValidationObserver,
        schedulesValidationObserver,
      } = this.$refs;

      return await generalValidationObserver.validate() &&
        await configurationValidationObserver.validate() &&
        await footersValidationObserver.validate() &&
        await cashOutsAndCommissionsValidationObserver.validate() &&
        await schedulesValidationObserver.validate();
    },
    loadBettingPool() {
      return fetchBettingPool(this.id).
        then(response => {
          const { bettingPool } = response.data;

          this.form.bettingPool = bettingPool;
          this.params.poolSequence = bettingPool.poolSequence;
          delete this.form.bettingPool.poolSequence;
        });
    },
    loadParams() {
      resetData(this.$data, data);
      return getCreateOrUpdateParams().
        then(response => {
          const paramsData = response.data.params;

          this.params = {
            ...paramsData,
          };

          if (this.currentMode === UPDATE_MODE) {
            this.loadBettingPool();
          } else {
            if (this.params.zones.length === 1) {
              this.form.bettingPool.zoneId = this.params.zones[0].value;
            }
          }
        });
    },
    errorClassForTab(tabName) {
      if (typeof this.$refs[tabName + "ValidationObserver"] !== "undefined") {
        const validationObserver = this.$refs[tabName + "ValidationObserver"];

        return validationObserver.flags.failed
          ? "tab-with-error"
          : "";
      }
    },
  },
};
</script>

<style lang="scss">
div.tab-with-error {
  background: #ffb8b8;
  border-top: 2px solid red;
}
</style>
