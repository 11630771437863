<template>
  <div>
    <validation-provider
      v-slot="{ errors }"
      :rules="validations.zones"
      name="zones"
      slim>
      <v-autocomplete
        :value="bettingPool.zoneId"
        :items="zones"
        :error-messages="errors"
        item-text="label"
        item-value="value"
        :label="$t('attributes.bettingPools.zone')"
        @input="zoneChanged" />
    </validation-provider>
    <validation-provider
      v-slot="{ errors }"
      :rules="validations.deactivationBalance"
      name="deactivation_balance"
      slim>
      <v-text-field
        :value="bettingPool.deactivationBalance"
        :label="$t('attributes.bettingPools.deactivation_balance')"
        :error-messages="errors"
        name="deactivation_balance"
        type="number"
        @input="deactivationBalanceChanged" />
    </validation-provider>
    <validation-provider
      v-slot="{ errors }"
      :rules="validations.dailySaleLimit"
      name="daily_sale_limit"
      slim>
      <v-text-field
        :value="bettingPool.dailySaleLimit"
        :label="$t('attributes.bettingPools.daily_sale_limit')"
        :error-messages="errors"
        name="daily_sale_limit"
        type="number"
        @input="dailySaleLimitChanged" />
    </validation-provider>
    <validation-provider
      v-slot="{ errors }"
      :rules="validations.maximumAmountPerPlay"
      name="maximum_amount_per_play"
      slim>
      <v-text-field
        :value="bettingPool.maximumAmountPerPlay"
        :label="$t('attributes.bettingPools.maximum_amount_per_play')"
        :error-messages="errors"
        name="maximum_amount_per_play"
        type="number"
        @input="(value) => $emit('update:betting-pool', {...bettingPool, maximumAmountPerPlay: value })" />
    </validation-provider>
    <validation-provider
      v-slot="{ errors }"
      :rules="validations.cancelTicketTime"
      name="cancel_ticket_time"
      slim>
      <v-text-field
        :value="bettingPool.cancelTicketTime"
        :label="$t('attributes.bettingPools.cancel_ticket_time')"
        :error-messages="errors"
        name="cancel_ticket_time"
        type="number"
        @input="cancelTicketTimeChanged" />
    </validation-provider>
    <btn-group-text
      :title="$t('attributes.bettingPools.winning_ticket_control')"
      :selected="bettingPool.winningTicketControl"
      :options="winningTicketControlModes"
      name="winning_ticket_control"
      color="info"
      attribute="name"
      mandatory
      translation-base="enumerations.winningTicketControlSettingCategory"
      @change="winningTicketControlChanged" />
    <validation-provider
      v-slot="{ errors }"
      :rules="validations.active"
      name="active"
      slim>
      <v-switch
        :input-value="bettingPool.active"
        :label="$t('attributes.bettingPools.active')"
        :error-messages="errors"
        name="active"
        class="pl-4"
        inset
        @click="activeChanged" />
    </validation-provider>
    <validation-provider
      v-slot="{ errors }"
      :rules="validations.allowChangePassword"
      name="allow_change_password"
      slim>
      <v-switch
        :value="bettingPool.allowChangePassword"
        :label="$t('attributes.bettingPools.allow_change_password')"
        :error-messages="errors"
        name="allow_change_password"
        class="pl-4"
        inset
        @click="allowChangePasswordChanged" />
    </validation-provider>
    <validation-provider
      v-slot="{ errors }"
      :rules="validations.allowExtendedRound"
      name="allow_extended_round"
      slim>
      <v-switch
        :value="bettingPool.allowExtendedRound"
        :label="$t('attributes.bettingPools.allow_extended_round')"
        :error-messages="errors"
        name="allow_extended_round"
        class="pl-4"
        inset
        @click="allowExtendedRoundChanged" />
    </validation-provider>
  </div>
</template>

<script>
import WinningTicketControlSettingCategory from "@/imported/WinningTicketControlSettingCategory.json";
import { mapJsonEnumToObjects } from "@/services/mappers/jsonEnumToObjectMapper.js";
import BtnGroupText from "../../components/inputs/BtnGroupText";

function data() {
  return {
    winningTicketControlModes: mapJsonEnumToObjects(WinningTicketControlSettingCategory),
  };
}

export default {
  name: "PoolConfigurationForm",
  components: { BtnGroupText },
  props: {
    bettingPool: {
      type: Object,
      required: true,
    },
    validations: {
      type: Object,
      required: true,
    },
    zones: {
      type: Array,
      required: true,
    },
  },
  data,
  methods: {
    activeChanged() {
      const updatedBettingPool = {
        ...this.bettingPool,
        active: !this.bettingPool.active,
      };

      this.$emit("update:betting-pool", updatedBettingPool);
    },
    zoneChanged(value) {
      const updatedBettingPool = {
        ...this.bettingPool,
        zoneId: value,
      };

      this.$emit("update:betting-pool", updatedBettingPool);
    },
    deactivationBalanceChanged(value) {
      const updatedBettingPool = {
        ...this.bettingPool,
        deactivationBalance: value,
      };

      this.$emit("update:betting-pool", updatedBettingPool);
    },
    dailySaleLimitChanged(value) {
      const updatedBettingPool = {
        ...this.bettingPool,
        dailySaleLimit: value,
      };

      this.$emit("update:betting-pool", updatedBettingPool);
    },
    cancelTicketTimeChanged(value) {
      const updatedBettingPool = {
        ...this.bettingPool,
        cancelTicketTime: value,
      };

      this.$emit("update:betting-pool", updatedBettingPool);
    },
    winningTicketControlChanged(value) {
      const updatedBettingPool = {
        ...this.bettingPool,
        winningTicketControl: value,
      };

      this.$emit("update:betting-pool", updatedBettingPool);
    },
    allowChangePasswordChanged() {
      const updatedBettingPool = {
        ...this.bettingPool,
        allowChangePassword: !this.bettingPool.allowChangePassword,
      };

      this.$emit("update:betting-pool", updatedBettingPool);
    },
    allowExtendedRoundChanged() {
      const updatedBettingPool = {
        ...this.bettingPool,
        allowExtendedRound: !this.bettingPool.allowExtendedRound,
      };

      this.$emit("update:betting-pool", updatedBettingPool);
    },
  },
};
</script>

<style
  lang="scss"
  scoped>
</style>
